
import {
  Component,
  Vue
} from 'vue-property-decorator'
import CollectionService from '../../../../services/collection-service'
import {
  JobModule
} from '../../../../store/JobModule'
import ScheduleService from '@/views/schedule/services/schedule-service'
import Status from '@/components/Status/index.vue'
import lazyLoad from '@/components/LazyLoad'
  @Component({
    name: 'JobOperation',
    components: {
      Status
    }
  })
export default class JobOperation extends Vue {
    protected component: any = null
    protected isLoader = false
    protected tripNumbers: any = []
    protected tripNumber: any = null
    protected scheduleStatus: any = null
    public allocatedBarge: any = []
    public barge: any = {}

    get job () {
      return JobModule.job
    }

    get body () {
      switch (this.component) {
        case 'allocation':
          return () => lazyLoad(import('./Allocation.vue'))

        case 'activity':
          return () => lazyLoad(import('./Activity.vue'))
      }
    }

    created () {
      this.boot()
    }

    protected boot () {
      this.populateJobScheduleStatus()
      CollectionService.getAllocatedBarge(JobModule.job.id).then((response) => {
        if (response.data.length === 0) this.component = 'allocation'
        else this.component = 'activity'
      })
    }

    protected async populateJobScheduleStatus () {
      const response = await ScheduleService.getJobScheduleStatus(JobModule.job.id)
      this.scheduleStatus = response.data.type
    }
}

